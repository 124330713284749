import type {
  ILeaseSummaryField,
  ILeaseSummaryFieldValue,
} from '@register/types'

export const useSummaryStore = defineStore('summary', () => {
  const fields = ref<ILeaseSummaryField[]>([])
  const selectedField = ref<ILeaseSummaryField>()
  const selectedFieldValue = ref<ILeaseSummaryFieldValue>()

  const clear = () => {
    fields.value = []
    selectedField.value = undefined
    selectedFieldValue.value = undefined
  }

  return {
    fields,
    selectedField,
    selectedFieldValue,
    clear,
  }
})
