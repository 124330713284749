import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ILeaseRegisterLeaseSummary } from '@register'
import type { Ref, MaybeRef } from 'vue'

export function invalidateApiLeaseSummary(
  divisionId: MaybeRef<string>,
  leaseId: MaybeRef<string>,
) {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({
    queryKey: getApiLeaseSummaryKey(divisionId, leaseId),
  })
}

export function getApiLeaseSummaryKey(
  companyId: MaybeRef<string>,
  leaseId: MaybeRef<string>,
) {
  return ['companies', companyId, 'leases', leaseId, 'summary']
}

export const useApiLeaseSummary = (
  companyId: string,
  leaseId: MaybeRef<string>,
  enabled: Ref<boolean> = ref(true),
) => {
  const { fields } = storeToRefs(useSummaryStore())
  const query = useQuery({
    queryKey: getApiLeaseSummaryKey(companyId, leaseId),
    queryFn: ({ signal }) =>
      fetchAuthorized<ILeaseRegisterLeaseSummary>(
        `/companies/${companyId}/leaseRegister/leases/${unref(leaseId)}/summary?version=current`,
        { signal },
      ),
    staleTime: 0,
    enabled: enabled,
  })

  watchEffect(() => {
    if (query.data.value) {
      fields.value = [
        ...(query.data.value.fields ?? []),
        ...(query.data.value.clausesAndDefinitions ?? []),
      ]
    }
  })

  return query
}
